import React, { Component } from "react";

import withLayout from "../layout";
import { FormattedMessage, injectIntl } from "react-intl";
import { withAppContext } from "../components/withAppContext";
import { apiFetchProfile } from "../util/api";
import { AccessibilityMenu } from "../components/AccessibilityMenu/AccessibilityMenu";

class IndexPage extends Component {
    state = {
        quota: { max: 0, used: 0 },
    };

    async componentDidMount() {
        const profile = await apiFetchProfile(
            this.props.appContext.user.username,
        );
        this.setState({ ...profile });
    }

    formatAmount(object) {
        const { locale } = this.props.intl;
        // let format = new Intl.NumberFormat(locale, { maximumFractionDigits: 3 });
        // the next line uses only the german as forced format
        let format = new Intl.NumberFormat("de", { maximumFractionDigits: 3 });
        return format.format(object);
    }

    render() {
        const { formatMessage } = this.props.intl;

        return (
            <div
                className="container"
                style={{
                    margin: `0 auto`,
                }}
            >
                <AccessibilityMenu i18nKey="home" />
                <h2>
                    <FormattedMessage id="home.headline" />
                </h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: formatMessage({ id: "home.content" }),
                    }}
                />
                <div className="divider" />
                <div
                    dangerouslySetInnerHTML={{
                        __html: `<p>${formatMessage({ id: "home.current_month_quota" })} 
            <strong> ${formatMessage({
                id: "home.current_month_used",
            })} </strong> ${this.formatAmount(this.state.quota.used)}  </p>`,
                    }}
                />
            </div>
        );
    }
}

const customProps = {
    localeKey: "home",
    withAuth: true,
    title: <FormattedMessage id="home.title" />,
};

export default withLayout(customProps)(withAppContext(injectIntl(IndexPage)));
